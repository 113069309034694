import { Env } from "@stencil/core";
import keysFn from "lodash/keys";
import forEach from "lodash/forEach";
import { detect } from "detect-browser";
import { logout } from "shared/helpers";

import type { DatabaseReference, Unsubscribe } from "firebase/database";
import {
	child,
	ref,
	push,
	set,
	update,
	remove,
	onValue,
	onDisconnect,
	serverTimestamp,
} from "firebase/database";
import { getToken } from "firebase/messaging";

import { database, messaging } from "global/firebase";
import { sessionsStore } from "global/session.store";

export interface SessionData {
	key: string;
	browser: {
		name: string;
		os: string;
		version: string;
	};
	online: boolean;
	endTime: number;
}

export class SessionService {
	// ==================== Class Properties ====================

	// ==================== Instance Properties ====================
	key: string;
	login: string;

	private _online: boolean;

	get online() {
		return this._online;
	}

	set online(online) {
		this.updateSession({ online });
		this.updateStatus({ online });

		this._online = online;
	}

	// ref
	private connectedRef: DatabaseReference;

	private sessionsRef: DatabaseReference;
	private sessionRef: DatabaseReference;
	private statusRef: DatabaseReference;
	private needToCloseRef: DatabaseReference;

	// unsubscribe
	private unsubscribeSessions: Unsubscribe = null;
	private unsubscribeConnected: Unsubscribe = null;
	private unsubscribeNeedToClose: Unsubscribe = null;

	// ==================== Class Methods ====================
	static async startSession(login: string) {
		if (!sessionsStore.session) {
			const session = new SessionService();
			sessionsStore.session = session;
			await session.init(login);
		}
	}

	static async closeSession() {
		if (sessionsStore.session) {
			sessionsStore.session.unSubscribe();
			sessionsStore.session.closeSession();

			sessionsStore.session = null;
			sessionsStore.sessions = [];
		}
	}

	static async needToCloseSession(key: string) {
		const ref = child(sessionsStore.session.sessionsRef, `${key}/needToClose`);
		await set(ref, true);
	}

	// ==================== Instance Methods ====================

	async init(login: string) {
		const key = localStorage.getItem("session");

		const browser = detect();
		const sessionsPath = "users_sessions";
		const statusesPath = "users_statuses";
		const statusPath = `${statusesPath}/${login}`;

		this.sessionsRef = child(ref(database, sessionsPath), login);
		this.statusRef = ref(database, statusPath);
		this.connectedRef = ref(database, ".info/connected");

		if (key) {
			this.sessionRef = child(this.sessionsRef, key);
			this.updateSession({
				endTime: 0,
			});
		} else {
			this.sessionRef = push(this.sessionsRef);
			localStorage.setItem("session", this.sessionRef.key);
			if (Env.envMode === "development") {
				await this.updateSession({
					development: true,
				});
			}

			await this.updateSession({
				browser: {
					name: browser.name,
					os: browser.os,
					version: browser.version,
				},
				online: true,
			});
		}

		this.needToCloseRef = child(this.sessionRef, "needToClose");
		this.login = login;
		this.online = true;
		this.key = this.sessionRef.key;

		this.subscribe();
	}

	async updateSession(data) {
		await update(this.sessionRef, data);
	}

	async updateStatus(data) {
		await update(this.statusRef, data);
	}

	private getSessions(data): SessionData[] {
		const result: SessionData[] = [];
		const keys = keysFn(data);
		forEach(keys, (key) => {
			if (data[key].needToClose) {
				return;
			}

			if (data[key].development) {
				return;
			}

			result.push({
				key: key,
				browser: data[key].browser,
				online: data[key].online,
				endTime: data[key].endTime ? data[key].endTime : 0,
			});
		});
		return result;
	}

	async closeSession() {
		localStorage.removeItem("session");
		await remove(this.sessionRef);
		await this.updateStatus({ online: false });
	}

	private async setStatusOnline() {
		this.online = true;
	}

	private async setStatusOffline() {
		this.online = false;
	}

	subscribe() {
		if (this.unsubscribeSessions === null) {
			this.unsubscribeSessions = onValue(this.sessionsRef, async (snap) => {
				const snapVal = snap.val();

				sessionsStore.sessions = await this.getSessions(snapVal);
			});
		}

		if (this.unsubscribeNeedToClose === null) {
			this.unsubscribeNeedToClose = onValue(
				this.needToCloseRef,
				async (snap) => {
					if (snap.val() === true) {
						await logout();
					}
				},
			);
		}

		if (this.unsubscribeConnected === null) {
			this.unsubscribeConnected = onValue(this.connectedRef, async (snap) => {
				if (snap.val() === true) {
					return await this.setStatusOnline();
				}

				return this.setStatusOffline();
			});
		}

		onDisconnect(this.sessionRef).update({
			online: false,
			endTime: serverTimestamp(),
		});
		onDisconnect(this.statusRef).update({
			online: false,
		});
	}

	unSubscribe() {
		if (this.unsubscribeSessions) {
			this.unsubscribeSessions();
			this.unsubscribeSessions = null;
		}

		if (this.unsubscribeNeedToClose) {
			this.unsubscribeNeedToClose();
			this.unsubscribeNeedToClose = null;
		}

		if (this.unsubscribeConnected) {
			this.unsubscribeConnected();
			this.unsubscribeConnected = null;
		}

		onDisconnect(this.sessionRef).cancel();
		onDisconnect(this.statusRef).cancel();
	}

	async getToken() {
		if (Notification.permission === "granted") {
			const token = await getToken(messaging, { vapidKey: Env.vapidKey });
			await this.updateSession({ token });
		} else if (Notification.permission === "default") {
			const permission = await Notification.requestPermission();
			if (permission === "granted") {
				const token = await getToken(messaging, { vapidKey: Env.vapidKey });
				await this.updateSession({ token });
			}
		}
	}
}

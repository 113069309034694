import {
	type User,
	signOut,
	onAuthStateChanged,
	signInWithEmailAndPassword,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { auth } from "global/firebase";

import {
	UserEntity,
	DeliveryEntity,
	PayBalanceEntity,
	CalculatorIndicesEntity,
} from "core/models";
import type { UserEntityData } from "core/typings";
import { redirect, presentToast, fbCallableFunction } from "shared/helpers";
import { homeUrl, vehiclesUrl } from "shared/config";
import { SessionService } from "core/services";
import store from "global/store";

import { onSnapshot, doc } from "firebase/firestore";
import type { Unsubscribe } from "firebase/firestore";

export const initAuth = async () => {
	await UserEntity.init();
	await DeliveryEntity.init();
	await PayBalanceEntity.init();
	await CalculatorIndicesEntity.init();

	let unsubscribe: Unsubscribe = null;

	await checkAuthOnce(async (userAuth) => {
		if (userAuth == null) {
			if (unsubscribe) {
				unsubscribe();
				unsubscribe = null;
			}

			redirect(homeUrl);

			store.users = [];
			store.deliveries = [];
			store.payBalances = [];

			store.user = null;
			store.theme = false;

			return await refresh();
		}

		const tokenResult = await userAuth.getIdTokenResult(true);
		const claims = tokenResult.claims;

		if (!claims.siteUser) {
			await userAuth.delete();
			return await logout();
		}

		unsubscribe = await onSnapshotUser(claims.login as string);

		await refresh();

		await SessionService.startSession(claims.login as string);

		return redirect(vehiclesUrl);
	});
};

export const login = async (email: string, password: string) => {
	try {
		return await signInWithEmailAndPassword(auth, email, password).catch(
			(err: FirebaseError) => {
				switch (err.code) {
					case "auth/invalid-credential":
					case "auth/invalid-email":
					case "auth/user-not-found":
					case "auth/wrong-password": {
						throw new Error(
							"Invalid credentials. Please check your information and try again.",
						);
					}
					case "auth/user-disabled": {
						throw new Error("This user has been disabled.");
					}
					case "auth/too-many-requests": {
						throw new Error(
							"Too many unsuccessful login attempts. Please try again later.",
						);
					}
					default: {
						throw new Error("Error login");
					}
				}
			},
		);
	} catch (err) {
		if (err instanceof FirebaseError) {
			presentToast(err.message, "error");
		} else if (err instanceof Error) {
			presentToast(err.message, "error");
		} else {
			presentToast("Login Error", "error");
		}

		return null;
	}
};

export const logout = async () => {
	await SessionService.closeSession();
	return signOut(auth);
};

export async function checkAuthOnce(
	callback: (user: User | null) => Promise<void>,
): Promise<void> {
	return new Promise((resolve) => {
		onAuthStateChanged(auth, async (user) => {
			await callback(user);
			resolve();
		});
	});
}

export const onSnapshotUser = (login: string): Promise<Unsubscribe> => {
	return new Promise((resolve) => {
		const unsubscribe = onSnapshot(doc(UserEntity.collRef, login), (doc) => {
			const user = doc.data();
			user.id = doc.id;

			store.user = user as UserEntity;
			store.theme = user.theme;

			resolve(unsubscribe);
		});
	});
};

export const refresh = async () => {
	await UserEntity.refresh();
	await DeliveryEntity.refresh();
	await PayBalanceEntity.refresh();
	await CalculatorIndicesEntity.refresh();
};

// Cloud Function
export const userCreate = async (data: UserEntityData) => {
	const fn = fbCallableFunction("userCreate");
	return await fn(data);
};

export const userDelete = async (login: string, email: string) => {
	const fn = fbCallableFunction("userDelete");
	return await fn({ login, email });
};

export const userUpdate = async (
	login: string,
	email: string,
	data: Omit<
		Partial<UserEntityData>,
		| "email"
		| "login"
		| "id"
		| "agreements"
		| "createdAt"
		| "createdBy"
		| "password"
		| "createdUsers"
	>,
) => {
	const fn = fbCallableFunction("userUpdate");
	return await fn({ login, email, data });
};

export const userUpdatePassword = async (
	login: string,
	email: string,
	password: string,
) => {
	const fn = fbCallableFunction("userUpdatePassword");
	return await fn({ login, email, newPassword: password });
};
